import * as React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { useKeenSlider } from 'keen-slider/react';

import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';

const courseName = [
  { name: 'Nền tảng', id: '1' },
  { name: 'IELTS 4.0 - 5.0', id: '2' },
  { name: 'IELTS 6.0 - 6.5', id: '3' },
  { name: 'IELTS 7.0+', id: '4' }
];

const normalizeDifferencesHTML = htmlContent => {
  return (
    htmlContent
      // replace p with span
      .replace(/<p[^>]*>/g, '<span>')
      .replace(/<\/p>/g, '</span>')
      // remove div
      .replace(/<div[^>]*>/g, '')
      .replace(/<\/div>/g, '')
  );
};

const courseInfo = [
  {
    id: '1',
    whoNeedCourse:
      'Hiện đang có rất nhiều bạn hoàn toàn mất gốc hoặc rất ít tiếp xúc với Tiếng Anh nên không biết bắt đầu học IELTS từ đâu, học như thế nào?<br/>Đây chính là chương trình học dành riêng cho bạn. Bạn sẽ phải mất khoảng 60-80 giờ học để hoàn thành mục tiêu IELTS 3.0. Các bài học ở từng kỹ năng được đội ngũ chuyên môn thiết kế với các chủ đề cơ bản nhất để bạn định hướng lại cách học đúng, hiểu được bản chất và củng cố lại nền tảng ngữ pháp và từ vựng tiếng Anh.',
    outputCourse: 'Đầu vào: 0.0 - Đầu ra: 3.0',
    method: [
      {
        icon: 'vZ2q2PNVS4Wt5eyOheWY',
        title: 'Tư duy học Tiếng Anh:',
        htmlContent:
          'Định hướng lại cách học tiếng Anh đúng, từ đó trở nên yêu thích việc học hơn.<br/>Hiểu được bản chất của tiếng Anh và sự khác biệt giữa tiếng Anh và tiếng Việt.'
      },
      {
        icon: 'TLQwmsTimHCLjxwVx7zQ',
        title: 'Từ vựng và Ngữ pháp:',
        htmlContent:
          'Tích luỹ những từ vựng cơ bản nhất cho từng chủ đề áp dụng được cho kỹ năng Nghe - Nói - Đọc - Viết<br/>Hiểu được cách học từ vựng/ ngữ pháp hiệu quả.'
      },
      {
        icon: '01dZJKUMS6bOuI0Mnrtq',
        title: 'Áp dụng:',
        htmlContent: 'Có khả năng nối từ để viết thành câu hoàn chỉnh'
      }
    ]
  },
  {
    id: '2',
    whoNeedCourse:
      'Bạn biết nhiều từ vựng tiếng Anh đơn lẻ nhưng chưa sử dụng đúng, không biết nghe, nói, đọc, viết như thế nào, thường sai các lỗi ngữ pháp và lỗi dùng từ cơ bản. <br/>Đây chính là chương trình học dành riêng cho bạn. Bạn sẽ phải mất khoảng 60-120 giờ học để hoàn thành mục tiêu IELTS 5.0 tùy vào đầu vào. Các bài học ở từng kỹ năng được đội ngũ chuyên môn thiết kế với các chủ đề cơ bản nhất để bạn nắm được cấu trúc bài thi và cách làm bài thi IELTS.',
    outputCourse: 'Đầu vào: 2.0-4.0 - Đầu ra: 4.0-5.0',
    method: [
      {
        icon: 'vZ2q2PNVS4Wt5eyOheWY',
        title: 'Tư duy học Tiếng Anh:',
        htmlContent:
          'Hình thành Tư duy học tiếng Anh đúng<br/>Bỏ Tư duy đọc dịch, nói dịch, viết dịch<br/>Xây nền tảng từ vựng, ngữ pháp căn bản<br/>Có kiến thức cơ bản về cấu trúc và cách tiếp cận các dạng bài thi IELTS.'
      },
      {
        icon: 'GnGPqvGQTIucoI26N77W',
        title: 'Reading',
        htmlContent:
          'Đọc hiểu nội dung văn bản ngắn<br/>Đọc hiểu nhanh, chính xác một bài hoàn chỉnh để trả lời câu hỏi.<br/>Có khả năng làm đúng trọn vẹn 13/13 câu của Passage 1.'
      },
      {
        icon: 'lIHZTwkGTgqup8RyF99j',
        title: 'Writing',
        htmlContent:
          'Viết câu đơn thành thạo.</br>  Biết diễn đạt và phát triển ý của mình. Có thể viết 1 đoạn ngắn và liên kết'
      },
      {
        icon: 'ThyRL3AIRQaONM1VCBpD',
        title: 'Speaking',
        htmlContent:
          'Nói câu đơn lưu loát<br/>Biết diễn đạt và phát triển ý của mình.'
      },
      {
        icon: 'SYPjmM72Szy4oh9WeDrq',
        title: 'Listening',
        htmlContent:
          'Rèn luyện khả năng nghe bằng nghe chép chính tả 25-50-75-100<br/>Tiếp cận với nội dung bài nghe IELTS'
      }
    ]
  },
  {
    id: '3',
    whoNeedCourse:
      'Các bạn có vốn từ vựng và ngữ pháp cơ bản. Có thể nghe, nói, đọc, viết cơ bản ở những chủ đề gần gũi và quen thuộc nhưng khi gặp chủ đề lạ hoặc cố gắng sử dụng từ vựng hoặc ngữ pháp nâng cao thường sai và không biết cách để suy nghĩ và phát triển ý tưởng.<br/>Đây chính là chương trình học dành riêng cho bạn. Bạn sẽ phải mất khoảng 60-120 giờ học để hoàn thành mục tiêu IELTS 6.0-6.5. Các bài học ở từng kỹ năng được đội ngũ chuyên môn thiết kế để bạn hệ thống lại kiến thức, phát triển khả năng sử dụng tiếng Anh cũng như nắm được cấu trúc bài thi và cách làm bài thi IELTS ở tất cả các kỹ năng.',
    outputCourse: 'Đầu vào: 5.0-5.5 - Đầu ra: 6.0-6.5',
    method: [
      {
        icon: 'vZ2q2PNVS4Wt5eyOheWY',
        title: 'Tư duy học Tiếng Anh:',
        htmlContent:
          'Hình thành tư duy học đúng. Bỏ Tư duy đọc dịch, viết dịch, nói dịch<br/>Có kiến thức về cấu trúc và cách tiếp cận bài thi IELTS hiệu quả.'
      },
      {
        icon: 'GnGPqvGQTIucoI26N77W',
        title: 'Reading',
        htmlContent:
          'Hiểu nhanh, chính xác nội dung một bài hoàn chỉnh thuộc nhiều chủ đề khó, lạ, thiếu từ vựng.<br/>Có khả năng làm đúng khoảng 25-30/40 câu của bài IELTS Reading.'
      },
      {
        icon: 'lIHZTwkGTgqup8RyF99j',
        title: 'Writing',
        htmlContent:
          'Viết một bài nhanh, tự nhiên và liên kết, kể cả khi gặp chủ đề lạ.<br/>Nắm được cách viết theo cấu trúc cho hầu hết các dạng thường gặp của Task 1 và Task 2.'
      },
      {
        icon: 'ThyRL3AIRQaONM1VCBpD',
        title: 'Speaking',
        htmlContent:
          'Nói một đoạn nhanh, lưu loát, tự nhiên và liên kết, kể cả khi gặp chủ đề lạ.'
      },
      {
        icon: 'SYPjmM72Szy4oh9WeDrq',
        title: 'Listening',
        htmlContent:
          'Rèn luyện khả năng nghe bằng nghe chép chính tả 25-50-75-100<br/>Nắm được chiến lược làm bài ở cả 4 section của bài thi IELTS Listening'
      }
    ]
  },
  {
    id: '4',
    whoNeedCourse:
      'Hiện đang có rất nhiều bạn bị chững ở band 6.0, 6.5. Học hoài mà vẫn không lên được band. Cày đề quá trời mà cũng không cải thiện được band điểm.<br/>Đây chính là chương trình học dành riêng cho bạn. Bạn sẽ phải mất khoảng 60-120 giờ học để hoàn thành mục tiêu IELTS 7.0+. Các bài học ở từng kỹ năng được đội ngũ chuyên môn thiết kế 1 cách tư duy và chi tiết nhất để bạn nắm được cấu trúc bài thi và cách xử lý tất cả các dạng của bài thi IELTS.',
    outputCourse: 'Đầu vào: 6.0+ - Đầu ra: 7.0+',
    method: [
      {
        icon: 'vZ2q2PNVS4Wt5eyOheWY',
        title: 'Tư duy học Tiếng Anh:',
        htmlContent:
          'Hình thành Tư duy học tiếng Anh đúng<br/>Bỏ Tư duy đọc dịch, nói dịch, viết dịch<br/>Củng cố lại nền tảng từ vựng, ngữ pháp, collocations<br/>Có kiến thức đầy đủ về cấu trúc và cách tiếp cận các dạng bài thi IELTS.'
      },
      {
        icon: 'GnGPqvGQTIucoI26N77W',
        title: 'Reading',
        htmlContent:
          'Đọc hiểu nhanh, chính xác các bài hoàn chỉnh ở nhiều chủ đề khác nhau ngay cả những chủ đề lạ và khó để trả lời câu hỏi.<br/>Có khả năng làm đúng khoảng >30/40 câu của bài IELTS Reading.'
      },
      {
        icon: 'lIHZTwkGTgqup8RyF99j',
        title: 'Writing',
        htmlContent:
          'Viết một bài nhanh, tự nhiên và liên kết, kể cả khi gặp chủ đề lạ.<br/>Nắm được cách viết theo cấu trúc cho tất cả các dạng thường gặp của Task 1 và Task 2.</br>Sử dụng từ vựng đúng, tự nhiên và nhiều cấu trúc đa dạng.'
      },
      {
        icon: 'ThyRL3AIRQaONM1VCBpD',
        title: 'Speaking',
        htmlContent:
          'Nói một đoạn nhanh, lưu loát, tự nhiên và liên kết, kể cả khi gặp chủ đề lạ.<br/> Sử dụng từ vựng đúng, tự nhiên và nhiều cấu trúc đa dạng.'
      },
      {
        icon: 'SYPjmM72Szy4oh9WeDrq',
        title: 'Listening',
        htmlContent:
          'Rèn luyện khả năng nghe bằng nghe chép chính tả 25-50-75-100<br/>Nắm được chiến lược làm bài ở cả 4 section của bài thi IELTS Listening.'
      }
    ]
  }
];

const IeltsRoadmap = () => {
  const [selected, setSelected] = React.useState(courseName[0].id);
  const [sliderRef] = useKeenSlider({
    slidesPerView: 3.2,
    breakpoints: {
      '(min-width: 776px)': {
        slidesPerView: 4
      }
    }
  });
  return (
    <Container>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        className="roadmap-txt"
        v3
        as="h2"
      >
        Lộ trình học IELTS ở DOL
      </Typography>
      <LazyImage handle="1bdWqpe0QPqjtZNcfsjT" alt="Lộ trình học IELTS" />
      <CourseNameWrapper>
        {courseName.map(elm => (
          <div
            className="course-name"
            key={elm.id}
            onClick={() => setSelected(elm.id)}
          >
            <Typography
              variant="semi-bold/16-24"
              desktopVariant="semi-bold/24-32"
              v3
              color={colorsV2.black80}
              className={cl({ selected: elm.id === selected })}
              as="h3"
            >
              {elm.name}
            </Typography>
          </div>
        ))}
      </CourseNameWrapper>

      <CourseInfoWrapper>
        {courseInfo.map(elm => {
          if (elm.id === selected)
            return (
              <div className="course-info" key={elm.id}>
                <div className="left-side">
                  <div>
                    <Typography
                      variant="semi-bold/16-24"
                      desktopVariant="semi-bold/20-28"
                      className="title"
                      v3
                      as="h4"
                    >
                      1. Khóa học này dành cho ai?
                    </Typography>
                    <Typography
                      variant="regular/14-20"
                      desktopVariant="regular/16-28"
                      v3
                      color={colorsV2.black80}
                      dangerouslySetInnerHTML={{
                        __html: normalizeDifferencesHTML(elm.whoNeedCourse)
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="semi-bold/16-24"
                      desktopVariant="semi-bold/20-28"
                      className="title"
                      v3
                      as="h4"
                    >
                      2. Đầu vào - Đầu ra của khóa học là bao nhiêu?
                    </Typography>
                    <Typography
                      variant="regular/14-20"
                      desktopVariant="regular/16-28"
                      v3
                      color={colorsV2.black80}
                      dangerouslySetInnerHTML={{
                        __html: normalizeDifferencesHTML(elm.outputCourse)
                      }}
                    />
                  </div>
                </div>
                <div className="right-side">
                  <Typography
                    variant="semi-bold/16-24"
                    desktopVariant="semi-bold/20-28"
                    className="title"
                    v3
                    as="h4"
                  >
                    3. Phương pháp Linear cho level này
                  </Typography>
                  {elm.method.map(item => (
                    <div key={item.id} className="method">
                      <div className="icon-title">
                        <LazyImage
                          imgProps={{
                            alt: 'iconMethod'
                          }}
                          handle={item.icon}
                        />
                        <Typography
                          variant="semi-bold/14-20"
                          v3
                          color={colorsV2.black80}
                        >
                          {item.title}
                        </Typography>
                      </div>
                      <Typography
                        variant="regular/14-20"
                        desktopVariant="regular/16-28"
                        v3
                        color={colorsV2.black80}
                        dangerouslySetInnerHTML={{
                          __html: normalizeDifferencesHTML(item.htmlContent)
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
        })}
      </CourseInfoWrapper>
    </Container>
  );
};

IeltsRoadmap.propTypes = {
  data: PropTypes.shape({
    lines: PropTypes.arrayOf(PropTypes.string),
    imgHandle: PropTypes.arrayOf(PropTypes.string)
  })
};

const CourseInfoWrapper = styled.div`
  .course-info {
    margin-top: 16px;
    display: grid;
    align-items: baseline;
    gap: 24px;
    ${fromScreen(776)} {
      grid-template-columns: 1fr 1fr;
      gap: 32px;
    }
    ${fromScreen(1144)} {
      gap: 40px;
    }
    .title {
      margin-bottom: 8px;
    }
    .left-side {
      display: grid;
      gap: 24px;
    }
    .right-side {
      .method {
        margin-bottom: 16px;
        .icon-title {
          display: grid;
          grid-template-columns: max-content max-content;
          gap: 6px;
          align-items: center;
          margin-bottom: 4px;
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 32px 0;
  .roadmap-txt {
    text-align: center;
    margin-bottom: 32px;
  }

  ${fromScreen(776)} {
    padding: 100px 0;
    .roadmap-txt {
      font-size: 40px;
      line-height: 48px;
    }
  }
`;

const CourseNameWrapper = styled.div`
  margin-top: 12px;

  overflow-y: hidden;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  .course-name {
    min-width: 130px !important;
    cursor: pointer;
    width: 100%;
    text-align: center;
    > h3 {
      padding: 12px 0;
      border-bottom: 1px solid ${colorsV2.gray20};
      transition: 0.3s;
      &:hover {
        color: ${colorsV2.black100};
      }

      &.selected {
        color: ${colorsV2.primary100};
        border-bottom: 2px solid ${colorsV2.primary100};
      }
    }
  }
`;

export default withHomePageSection(
  IeltsRoadmap,
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
