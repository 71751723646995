import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@dolstaff/shared/es/Button';

import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';
import { LazyImage } from 'components/LazyImage';
import DolInfo from 'shared/DolInfo';
import { UserPixelTracking } from 'utils/userPixelTracking';

const overviews = [
  {
    title: '7.0',
    desc: '<p> Điểm IELTS trung bình </br> của các học viên </p>'
  },
  { title: '15K+', desc: '<p> Học viên đã học </br> khóa IELTS tại DOL </p>' },
  { title: '12K+', desc: '<p> Review 5 sao </br> từ các học viên </p>' }
];

const OverViewDol = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 128px);
  gap: 63px;
`;
const ColInfo = styled.div``;

const normalizeDifferencesHTML = htmlContent => {
  return (
    htmlContent
      // replace p with span
      .replace(/<p[^>]*>/g, '<span>')
      .replace(/<\/p>/g, '</span>')
      // remove div
      .replace(/<div[^>]*>/g, '')
      .replace(/<\/div>/g, '')
  );
};

const Header = () => {
  const onClick = e => {
    UserPixelTracking.clickEvent();
  };

  return (
    <Container>
      <div className="content">
        <Typography
          className="brand"
          variant="bold/32-49"
          tabletVariant="bold/40-48"
          desktopVariant="bold/48-58"
          v3
          as="h1"
        >
          Khóa học <span className="highlight">IELTS</span>
        </Typography>

        <Typography
          className="title"
          variant="regular/16-24"
          desktopVariant="regular/20-28"
          color={colorsV2.black80}
          v3
        >
          Các khóa học IELTS tại DOL English luôn cam kết đảm bảo đầu ra cho học
          viên với phương pháp Linearthinking và nền tảng công nghệ độc quyền do
          DOL phát triển.
        </Typography>

        <DolInfo>
          {({ dolInfo }) => (
            <Link
              target="_blank"
              href={dolInfo.messenger.href}
              rel="noreferrer noopener"
            >
              <Button
                size="large"
                className="btn-action"
                type="primary"
                marginBottom="0"
                onClick={onClick}
              >
                <Typography variant="semi-bold/14-24" color={colorsV2.white100}>
                  Nhắn tin DOL qua FB
                </Typography>
              </Button>
            </Link>
          )}
        </DolInfo>

        <OverViewDol>
          {overviews.map((item, key) => (
            <ColInfo key={key}>
              <Typography variant="bold/24-33" v3 color={colorsV2.primary100}>
                {item.title}
              </Typography>
              <Typography
                variant="regular/12-17"
                v3
                color={colorsV2.black60}
                dangerouslySetInnerHTML={{
                  __html: normalizeDifferencesHTML(item.desc)
                }}
              />
            </ColInfo>
          ))}
        </OverViewDol>
      </div>

      <div className="right-section">
        <div className="circle">
          <LazyImage
            imgProps={{
              alt: 'Học viên IELTS tại DOl'
            }}
            className="student-img"
            handle="aWBvGBAHTn2N7yUmSznY"
          />
        </div>

        <LazyImage
          handle="RY1uavGWQqW2aZ0GGJDo"
          className="icon top"
          alt="icon-start"
        />
        <LazyImage
          handle="pZtoUY3mStuWmaQ5lBx6"
          className="icon left"
          alt="icon-arrow"
        />
        <LazyImage
          handle="1KmuTBLmS4uEu98y6Tp3"
          className="icon bottom"
          alt="icon-wavy"
        />
      </div>
    </Container>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    lines: PropTypes.arrayOf(PropTypes.string),
    imgHandle: PropTypes.arrayOf(PropTypes.string)
  })
};

const Container = styled.div`
  padding: 32px 0;
  .content {
    .brand {
      margin-bottom: 32px;
    }
    .title {
      margin-bottom: 36px;
    }
    .btn-action {
      border-radius: 8px;
    }
  }
  ${OverViewDol},.right-section {
    display: none;
  }

  .highlight {
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      background: ${colorsV2.yellow100};
      bottom: 3px;
      left: 0;
      height: 10px;
      width: 100%;
      opacity: 0.3;
      z-index: -1;
    }
  }

  ${fromScreen(776)} {
    display: grid;
    grid-template-columns: 386px 1fr;
    align-items: flex-end;
    padding: 100px 0;
    .content {
      .title {
        display: block;
      }
    }

    .right-section {
      .icon {
        position: absolute;
        &.bottom,
        &.left {
          display: none;
        }
        &.top {
          right: 30px;
        }
      }
      display: block;
      height: 352px;
      position: relative;
      .circle {
        position: absolute;
        width: 306px;
        height: 306px;
        border-radius: 50%;
        right: 0;
        bottom: 0;
        background: ${colorsV2.yellow20};
        z-index: 1;
      }
      .student-img {
        position: absolute;
        z-index: 5;
        right: 0;
        bottom: 0px;
        > img {
          margin-left: 0px;
          clip-path: polygon(5%);
          border-bottom-right-radius: 224px;
          border-bottom-left-radius: 190px;
        }
      }
    }
  }

  ${fromScreen(1144)} {
    grid-template-columns: 1fr 500px;
    .right-section {
      height: 512px;
      .circle {
        width: 436px;
        height: 436px;
      }
      .icon {
        &.bottom {
          bottom: 0;
          right: 0;
          display: block;
        }
        &.left {
          bottom: 70px;
          left: 10px;
          display: block;
        }
      }
    }
    ${OverViewDol} {
      display: grid;
    }
    .btn-action {
      margin-bottom: 100px;
    }
  }
`;

export default Header;

export const GCMSHeader = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "LandingCourse_Header" }) {
            content
          }
        }
      }
    `);

    return <Header data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
